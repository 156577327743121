import axios from 'axios';
import { defineComponent } from 'vue';
import Loader from '@/core/components/ui/Loader.vue';
import StringHelper from '@/core/helpers/string.helper';
import { VUE_APP_EPC_API, } from '../../../config';
const DISPLAY = 'display';
const DOMESTIC = 'domestic';
const NON_DOMESTIC = 'non-domestic';
export default defineComponent({
    components: {
        Loader
    },
    data() {
        return {
            DISPLAY,
            DOMESTIC,
            NON_DOMESTIC,
            searchKey: '',
            addresses: [],
            selectedKey: '',
            findingAddress: false,
            loadingReccomendation: false,
            lmkKey: null,
            recommendation: null,
            hasNoRecommendation: false,
            epcFilter: DOMESTIC,
            epcOptions: [{
                    value: DISPLAY,
                    label: StringHelper.capitalizeFirstLetter(DISPLAY)
                },
                {
                    value: NON_DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(NON_DOMESTIC)
                },
                {
                    value: DOMESTIC,
                    label: StringHelper.capitalizeFirstLetter(DOMESTIC)
                }],
            epcUrls: {
                display: 'https://epc.opendatacommunities.org/api/v1/display',
                domestic: 'https://epc.opendatacommunities.org/api/v1/domestic',
                'non-domestic': 'https://epc.opendatacommunities.org/api/v1/non-domestic'
            },
        };
    },
    methods: {
        onChangeFilter() {
            this.onSearchAddress();
        },
        async onSearchAddress() {
            this.addresses = [];
            if (this.searchKey.length > 3) {
                this.findingAddress = true;
                this.hasNoRecommendation = false;
                this.recommendation = null;
                const url = `${this.epcUrls[this.epcFilter]}/search`;
                const postcode = this.searchKey;
                await axios(`${url}?postcode=${postcode}&size=500`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Basic ${VUE_APP_EPC_API}`
                    }
                }).then((response) => {
                    this.addresses = response.data?.rows || [];
                    this.findingAddress = false;
                }).catch(() => {
                    this.$notify.error({
                        title: 'Error in finding address',
                        message: 'Please enter a valid postcode'
                    });
                });
            }
        },
        async onSelectAddress(row) {
            this.hasNoRecommendation = false;
            this.loadingReccomendation = true;
            this.recommendation = null;
            const lmkKey = row['lmk-key'] || '';
            this.selectedKey = lmkKey;
            if (!lmkKey) {
                this.hasNoRecommendation = true;
                this.loadingReccomendation = false;
            }
            else {
                await this.getEpcRecommendationData(lmkKey)
                    .then(() => {
                    // ..
                })
                    .catch(() => {
                    this.hasNoRecommendation = true;
                })
                    .finally(() => {
                    this.loadingReccomendation = false;
                });
            }
        },
        async getEpcRecommendationData(lmk) {
            try {
                const url = `${this.epcUrls[this.epcFilter]}/recommendations`;
                const response = await axios.get(`${url}/${lmk}`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Basic ${VUE_APP_EPC_API}`,
                    }
                });
                if (response.data) {
                    this.recommendation = response.data;
                }
                this.loadingReccomendation = false;
            }
            catch (e) {
                this.loadingReccomendation = false;
            }
        }
    },
});
